import { Box, IconProps } from "@chakra-ui/react"

import ArrowRightIcon from "@/icons/ArrowRight"

type ArrowSliderProps = IconProps & {
  onClick: () => void
  direction: "left" | "right"
  hide: boolean
}

const directionMap = {
  left: <ArrowRightIcon transform={"rotate(180deg)"} />,
  right: <ArrowRightIcon />,
}

export const ArrowSlider = ({ direction, onClick, hide }: ArrowSliderProps) => {
  return (
    <Box width="64px" as="button" pb={{ base: 3 }} px={4} onClick={onClick}>
      {hide ? <></> : directionMap[direction]}
    </Box>
  )
}
