import { Flex, Spinner } from "@chakra-ui/react"
import { debounce } from "lodash"
import { forwardRef, useRef, useImperativeHandle, useState } from "react"
import { useSwipeable } from "react-swipeable"

import TopSearchItem from "./TopSearchItem"
import { TopSearchSection } from "./TopSearchSection"
import { TopSearchDataType } from "@/search/types"
import { useSwipe } from "@/ui/swiper/SwipeProvider"

interface ChildProps {
  data: TopSearchDataType[]
}

export interface TopSearchGridRef {
  scrollToNextSection: () => void
  scrollToPrevSection: () => void
  getIndex: () => number
  getSectionCount: () => number
}

const MAX_SECTION_SIZE = 10

export const TopSearchGrid = forwardRef<TopSearchGridRef, ChildProps>(
  ({ data }, ref) => {
    const { setShouldCancel } = useSwipe()
    const { ref: swipeRef, ...cancelHandlers } = useSwipeable({
      onSwipeStart: () => {
        setShouldCancel.on()
      },
      onSwiped: debounce(() => {
        setShouldCancel.off()
      }, 100),
    })

    if (!data.length) {
      return (
        <Flex justify="center" py={10}>
          <Spinner
            data-testid="top-search-spinner"
            emptyColor="snow.blue-light"
          />
        </Flex>
      )
    }

    const numSections = Math.ceil(data.length / MAX_SECTION_SIZE)

    const sections = Array.from({ length: numSections }, (_, i) => {
      const start = i * MAX_SECTION_SIZE
      const end = start + MAX_SECTION_SIZE
      const sectionData = data.slice(start, end)
      return sectionData.map((item: TopSearchDataType, index: number) => {
        const gridRow =
          sectionData.length === MAX_SECTION_SIZE
            ? undefined
            : Math.floor(index / Math.ceil(sectionData.length / 2)) + 1

        return <TopSearchItem key={index} {...item} gridRow={gridRow} />
      })
    })

    const sectionsRef = useRef<HTMLDivElement[]>([])

    const [scrollIndex, setScrollIndex] = useState(0)

    useImperativeHandle(ref, () => ({
      scrollToNextSection: () => {
        if (scrollIndex < sections.length - 1) {
          sectionsRef.current[scrollIndex + 1].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          })
          setScrollIndex(scrollIndex + 1)
        }
      },
      scrollToPrevSection: () => {
        if (scrollIndex > 0) {
          sectionsRef.current[scrollIndex - 1].scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          })
          setScrollIndex(scrollIndex - 1)
        }
      },
      getIndex: () => scrollIndex,
      getSectionCount: () => sections.length,
    }))

    return (
      <Flex
        ref={swipeRef}
        pb={3}
        px={3}
        ml={-3}
        mr={{ base: 0, lg: -3 }}
        data-testid="top-search-grid"
        {...cancelHandlers}
      >
        {sections.map((section, index) => (
          <TopSearchSection
            key={index}
            sectionsRef={sectionsRef}
            index={index}
            section={section}
          />
        ))}
      </Flex>
    )
  },
)
