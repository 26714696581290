import { Box, Text } from "@chakra-ui/react"

import MailSnowdayButton from "../../ui/buttons/MailSnowdayButton"

export default function SearchFooter() {
  const paddingText = 5
  const paddingBox = 5

  return (
    <Box p={paddingBox} w={{ base: "100%" }}>
      <Text
        align="center"
        color="snow.blue-dark"
        fontWeight="bold"
        pt={paddingText}
        pb={paddingText}
      >
        Can't find what you're looking for?
      </Text>
      <MailSnowdayButton />
      <Text
        align="center"
        color="snow.blue-dark"
        pt={paddingText}
        pb={paddingText}
      >
        Tell us what you're looking for. Our team will research and respond in
        48 hours.
      </Text>
    </Box>
  )
}
