import { Box, Link, Text } from "@chakra-ui/react"

import { SearchTypes } from "../types"
import { generateResultUrl } from "../utils/domain"
import { makeBold } from "@/utils/string"

type Item = {
  identifier: string
  name: string
}

export interface SearchResultsProps {
  type: SearchTypes
  name: string
  data: (Item | null)[]
  isFetching: boolean
  query: string
}

const SearchResultsItem = ({
  query,
  type,
  item,
}: {
  query: string
  type: SearchTypes
  item: Item
}) => {
  const { name, identifier } = item

  return (
    <Link
      display={"block"}
      key={`item-${identifier}`}
      href={generateResultUrl(type, name, identifier)}
      dangerouslySetInnerHTML={{
        __html: name ? makeBold(query, name) : "",
      }}
    />
  )
}

const SearchResults = ({
  type,
  name,
  data,
  isFetching,
  query,
}: SearchResultsProps) => {
  return (
    <Box w="100%">
      <Text fontStyle="italic" opacity={0.5} color="snow.blue-dark">
        {name}
      </Text>
      <Box pl={{ base: 7 }}>
        {data?.length ? (
          data
            .filter((e: Item | null): e is Item => !!e)
            .map((item) => (
              <SearchResultsItem query={query} type={type} item={item} />
            ))
        ) : isFetching ? (
          <Text>Searching.</Text>
        ) : (
          <Text>No results.</Text>
        )}
      </Box>
    </Box>
  )
}

export default SearchResults
