import {
  Center,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react"
import { useRouter } from "next/router"
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react"

import { useSearch } from "@/features/search/providers/SearchProvider"
import SnowdayIcons, { iconTypes } from "@/icons/SnowdayIcons"
import useDebounce from "@/ui/hooks/useDebounce"
import SearchIcon from "@/ui/icons/Search"

interface SearchInputProps {
  onChange?: (v: string) => void
  onClick?: () => void
  goBack?: boolean
  icon?: keyof typeof iconTypes
  isLoading?: boolean
}

export default function SearchInput({
  onClick,
  goBack = true,
  icon = "Search",
  isLoading = false,
}: SearchInputProps) {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { back, push } = useRouter()

  const { query: savedQuery, setQuery: setSavedQuery } = useSearch()
  const [currentQuery, setCurrentQuery] = useState(savedQuery)

  const debouncedQuery = useDebounce(currentQuery, 300)

  useEffect(() => {
    setSavedQuery(debouncedQuery)
  }, [debouncedQuery])

  useEffect(() => {
    if (inputRef.current && goBack) {
      inputRef.current.focus()
    }
  }, [inputRef.current, goBack])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setCurrentQuery(value)
  }

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  const handleSearch = () => {
    push(`/search/results?query=${currentQuery}`)
  }

  const renderSpinner = () => {
    if (!isLoading) {
      return
    }

    return (
      <Center>
        <Spinner
          data-testid="search-input-loading"
          size="sm"
          color="snow.blue"
        />
      </Center>
    )
  }

  const renderInputRightChildren = () => {
    return (
      <>
        {renderSpinner()}
        <Center marginLeft={3}>
          <IconButton
            isRound={true}
            size={{ base: "xs", lg: "md" }}
            aria-label="Search"
            width={{
              base: "var(--chakra-sizes-6)",
              lg: "var(--chakra-sizes-10)",
            }}
            icon={
              <SearchIcon
                width={{ base: "16px", lg: "24px" }}
                height={{ base: "16px", lg: "24px" }}
              />
            }
            isDisabled={!currentQuery || currentQuery.trim() === ""}
            onClick={handleSearch}
          />
        </Center>
      </>
    )
  }

  return (
    <InputGroup
      data-testid="search-input"
      pointerEvents={!goBack ? "none" : "all"}
      alignItems="center"
      flexDirection="row"
    >
      <InputLeftElement
        cursor="pointer"
        children={<SnowdayIcons name={icon} color="snow.blue-medium" />}
        onClick={onClick ? onClick : goBack ? back : () => null}
        top={{ lg: "8px" }}
      />
      <Input
        data-testid="search-input-input"
        ref={inputRef}
        placeholder="Search by interest, name..."
        _placeholder={{
          opacity: 1,
          color: "gray.500",
          fontStyle: "italic",
        }}
        backgroundColor="white"
        height={{ base: "40px", lg: "56px" }}
        py={{ lg: 5 }}
        value={currentQuery ?? ""}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
      <InputRightElement
        justifyContent="right"
        width={100}
        paddingRight={3}
        top={{ lg: "8px" }}
        children={renderInputRightChildren()}
      />
    </InputGroup>
  )
}
