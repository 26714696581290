import { Box, Flex, Heading, TextProps } from "@chakra-ui/react"

interface ComponentHeadingProps extends TextProps {
  title: string
  subtitle?: string
  icon: React.ReactNode
}

export default function ComponentHeading(props: ComponentHeadingProps) {
  return (
    <Flex alignItems="flex-start" gap={2} pb={{ base: 3 }}>
      <Box mt={{ lg: 0.5 }}>{props.icon}</Box>
      <Box>
        <Heading variant="h2" {...props}>
          {props.title}
        </Heading>
        <Heading variant="h3" {...props} fontWeight={400}>
          {props.subtitle}
        </Heading>
      </Box>
    </Flex>
  )
}
